const sessionVuexModule = {
  state: {
    loggedUser: undefined,
    privateKey: undefined
  },

  mutations: {
    setLoggedUser: (state, user) => (state.loggedUser = user),
    setPrivateKey: (state, user) => (state.privateKey = user)
  },

  getters: {
    loggedUser: state => state.loggedUser,
    privateKey: state => state.privateKey
  }
};

export default sessionVuexModule;
