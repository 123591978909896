import Vue from "vue";
import Vuetify from "vuetify/lib";
import * as allLocales from "vuetify/es5/locale/index";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: allLocales,
    current: "it"
  },
  icons: {
    iconfont: "mdi"
  }
});
