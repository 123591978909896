import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

export default new VueRouter({
  routes: [
    {
      path: "/",
      redirect: "/profile"
    },
    {
      path: "/login",
      name: "UserLogin",
      component: () =>
        import(/* webpackChunkName: "login" */ "../views/UserLogin.vue")
    },
    {
      path: "/profile",
      name: "UserProfile",
      component: () =>
        import(/* webpackChunkName: "profile" */ "../views/UserProfile.vue")
    },
    {
      path: "/forgotten-password",
      name: "Forgotten",
      component: () =>
        import(
          /* webpackChunkName: "forgotten" */ "../views/ForgottenPassword.vue"
        )
    },
    {
      path: "/dictionary",
      name: "AppDictionary",
      component: () =>
        import(/* webpackChunkName: "dictionary" */ "../views/AppDictionary.vue")
    }
  ]
});
