<template>
  <v-app class="fill-height">
    <v-app-bar app color="navbar" dark>
      <img src="company_logo.png" height="90%" class="mr-2" />
      <h1>{{ appTitle }}</h1>

      <v-spacer />

      <v-menu offset-y v-if="languages.length > 1">
        <template v-slot:activator="{ on }">
          <v-btn plain v-on="on">
            <v-icon left>mdi-web</v-icon>
            {{ currentLanguage }}
          </v-btn>
        </template>
        <v-list dense class="pa-0">
          <v-list-item
            v-for="language in languages"
            v-bind:key="language"
            v-show="currentLanguage !== language"
            v-on:click="currentLanguage = language"
          >
            <v-list-item-title>{{ language.toUpperCase() }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <span class="caption ml-10 mr-1">v{{ appVersion }}</span>
      <v-icon v-if="session_encryptionEnabled" small>mdi-shield-lock</v-icon>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <alert-snackbar />
    <auth-code />
  </v-app>
</template>

<script>
import AlertSnackbar from "./components/AlertSnackbar";
import AuthCode from "./components/AuthCode";

export default {
  name: "App",

  components: { AlertSnackbar, AuthCode },

  data: () => ({
    appTitle: "",
    appVersion: ""
  }),

  computed: {
    languages() {
      return this.$store.getters.availableLanguages;
    },
    currentLanguage: {
      get: function() {
        return this.$store.getters.defaultLanguage;
      },
      set: function(value) {
        if (value != this.$store.getters.defaultLanguage) {
          this.$vuetify.lang.current = value;
          this.$dictionary.current = value;
          this.$cookies.set("app-language", value, -1);
          this.$store.commit("setDefaultLanguage", value);

          // If the user is logged in change its default language
          this.session_loggedIn && this.$server.update({ url: "/users/current", data: { language: value } });
        }
      }
    }
  },

  created() {
    // Set app title and version
    this.appTitle = this.$store.getters.appTitle;
    this.appVersion = process.env.VUE_APP_VERSION;

    // Check if language is saved in cookies
    const cookieValue = this.$cookies.get("app-language");
    this.currentLanguage =
      cookieValue && this.languages.includes(cookieValue)
        ? cookieValue
        : this.$store.getters.defaultLanguage;

    // Register default error handler for server communications
    this.$server.defaultErrorHandler = (error, errorFilter) => {
      if (error.status === 401) {
        this.session_loggedIn = false;
        this.$router.replace({ path: "/login" });
      } else if (
        errorFilter === null ||
        typeof errorFilter !== "function" ||
        !errorFilter(error)
      ) {
        this.$alert.raiseHttpError(
          this.$dictionary.key.APP_GENERIC_ERROR,
          error
        );
      }
    };
  }
};
</script>
