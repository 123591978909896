const _isNaNValue = "-";
const _sizeFormatter = new Intl.NumberFormat("it-IT", {
  maximumFractionDigits: 1
});

/**
 * Default exports
 */
export default {
  methods: {
    sizes_bytes(value) {
      return isNaN(value)
        ? _isNaNValue
        : _sizeFormatter.format(value) + "Bytes";
    },

    sizes_kb(value) {
      return isNaN(value)
        ? _isNaNValue
        : _sizeFormatter.format(value / 1024) + "Kb";
    },

    sizes_mb(value) {
      return isNaN(value)
        ? _isNaNValue
        : _sizeFormatter.format(value / 1024 / 1024) + "Mb";
    },

    sizes_gb(value) {
      return isNaN(value)
        ? _isNaNValue
        : _sizeFormatter.format(value / 1024 / 1024 / 1024) + "Gb";
    },

    sizes_auto(value) {
      const labels = ["Bytes", "Kb", "Mb", "Gb"];

      if (isNaN(value)) {
        return _isNaNValue;
      } else {
        let count = 0;
        while (value > 1000 && count < labels.length - 1) {
          value = value / 1024;
          count++;
        }

        return _sizeFormatter.format(value) + labels[count];
      }
    }
  }
};
