/**
 * Default exports
 */
export default {
  data: () => ({
    session_roleSuperAdmin: "superadmin",
    session_roleAdmin: "admin",
    session_roleCompany: "company",
    session_iconSuperAdmin: "mdi-shield-account",
    session_iconAdmin: "mdi-account-tie",
    session_iconCompany: "mdi-factory",
    session_iconUnknown: "mdi-help-circle"
  }),

  computed: {
    // Return or set current logged user
    session_loggedUser: {
      get() {
        return this.$store.getters.loggedUser;
      },
      set(value) {
        this.$store.commit("setLoggedUser", value);
      }
    },

    // Return logged user id
    session_loggedUserId() {
      return (this.session_loggedUser || {})._id;
    },

    // Return or set current logged state
    session_loggedIn: {
      get() {
        return !!this.$store.getters.loggedUser;
      },
      set(value) {
        value === false && this.$store.commit("setLoggedUser", undefined);
        value === false && this.$store.commit("setPrivateKey", undefined);
      }
    },

    // Return or set encryption state
    session_encryptionEnabled: {
      get() {
        return this.$store.getters.encryption;
      },
      set(value) {
        this.$store.commit("setEncryption", value);
      }
    },

    // Return encryption options
    session_encryptionOptions() {
      return {
        commonKey: this.$store.getters.encryptionCommonKey,
        rsaBits: this.$store.getters.encryptionRsaBits
      };
    }
  },

  methods: {
    // Return true if user is a super admin
    session_isSuperAdmin(user) {
      return user && user.role === this.session_roleSuperAdmin;
    },

    // Return true if user is an admin
    session_isAdmin(user) {
      return user && user.role === this.session_roleAdmin;
    },

    // Return true if user is a company
    session_isCompany(user) {
      return user && user.role === this.session_roleCompany;
    },

    // Get user icon by role
    session_userIcon(user) {
      switch (user?.role) {
      case this.session_roleSuperAdmin:
        return this.session_iconSuperAdmin;
      case this.session_roleAdmin:
        return this.session_iconAdmin;
      case this.session_roleCompany:
        return this.session_iconCompany;
      default:
        return this.session_iconUnknown;
      }
    }
  }
};
