import Vue from "vue";

export class AlertHandler {
  /**
   * Class constructor.
   */
  constructor() {
    this._listeners = [];
  }

  /**
   * Raise an error with the given title and http result.
   * @param {string} title error title
   * @param {object} error axios http error
   */
  raiseHttpError(title, error) {
    let errorDesc;

    if (error?.data?.errorCode) {
      errorDesc =
        Vue.prototype.$dictionary.key[error.data.errorCode] ||
        `${error.data.errorDesc} (${error.data.errorCode})`;
    } else if (error.data) {
      errorDesc =
        typeof error.data === "object"
          ? JSON.stringify(error.data)
          : error.data;
    } else {
      errorDesc = Vue.prototype.$dictionary.key[`HTTP_ERROR_${error.status}`];
    }

    // Transform axios network errors
    this.raiseError(title, errorDesc);
  }

  /**
   * Raise an error with the given title and description.
   * @param {string} title error title
   * @param {string} description error description
   */
  raiseError(title, description = "") {
    this.raiseAlert("error", title, description);
  }

  /**
   * Raise a warning with the given title and description.
   * @param {string} title warning title
   * @param {string} description warning description
   */
  raiseWarning(title, description = "") {
    this.raiseAlert("warning", title, description);
  }

  /**
   * Raise a success with the given title and description.
   * @param {string} title success title
   * @param {string} description success description
   */
  raiseSuccess(title, description = "") {
    this.raiseAlert("success", title, description);
  }

  /**
   * Raise an alert of the given type, title and description.
   * @param {string} type alert type
   * @param {string} title alert title
   * @param {string} description alert description
   */
  raiseAlert(type, title, description = "") {
    this._listeners.forEach(listener =>
      listener({
        type: type,
        title: title || "",
        description: description || ""
      })
    );
  }

  /**
   * Add a listener function when an error is raised.
   * @param {function} listener listener function
   */
  addListener(listener) {
    const index = this._listeners.indexOf(listener);

    listener &&
      typeof listener === "function" &&
      index === -1 &&
      this._listeners.push(listener);
  }

  /**
   * Remove an error listener function.
   * @param {function} listener listener function
   */
  removeListener(listener) {
    listener &&
      (this._listeners = this._listeners.filter(item => item != listener));
  }
}

export default {
  /**
   * Install default error handler instance.
   * @param {object} Vue vue instance
   */
  install: function(Vue) {
    Vue.prototype.$alert = new AlertHandler();
  }
};
