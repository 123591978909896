import moment from "moment";

/**
 * Date time common filters
 */

// Load locale
moment.locale("it");

/**
 * Default exports
 */
export default {
  methods: {
    dates_date(value) {
      return value ? moment(String(value)).format("DD/MM/YYYY") : undefined;
    },

    dates_dateSmall(value) {
      return value ? moment(String(value)).format("DD/MM") : undefined;
    },

    dates_dateTime(value) {
      return value
        ? moment(String(value)).format("DD/MM/YYYY HH:mm")
        : undefined;
    },

    dates_dateTimeSmall(value) {
      return value ? moment(String(value)).format("DD/MM HH:mm") : undefined;
    },

    dates_dateTimeFull(value) {
      return value
        ? moment(String(value)).format("DD/MM/YYYY HH:mm:ss")
        : undefined;
    },

    dates_dateTimeExtended(value) {
      return value
        ? moment(String(value)).format("[il] DD/MM/YYYY [alle] HH:mm")
        : undefined;
    },

    dates_time(value) {
      return value ? moment(String(value)).format("HH:mm") : undefined;
    }
  }
};
