<template>
  <v-snackbar
    v-bind:color="alertType"
    v-bind:top="true"
    v-bind:timeout="timeout"
    v-model="visible"
  >
    <h3 v-if="alertTitle">{{ alertTitle }}</h3>
    {{ alertDescription }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" v-on:click="visible = false">
        {{ $dictionary.key.APP_CLOSE }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "AlertSnackbar",

  props: {
    timeout: { type: Number, default: 3000 }
  },

  data: () => ({
    visible: false,
    alert: {}
  }),

  computed: {
    // Alert type
    alertType: function() {
      switch (this.alert.type) {
      case "warning":
      case "success":
      case "error":
        return this.alert.type;
      default:
        return "error";
      }
    },

    // Alert title
    alertTitle: function() {
      return this.alert.title || "";
    },

    // Alert description
    alertDescription: function() {
      return this.alert.description || "";
    }
  },

  created() {
    // Register to alerts to show them
    this.$alert &&
      this.$alert.addListener(alert => {
        this.alert = alert;
        this.visible = true;
      });
  }
};
</script>
