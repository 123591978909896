import Vue from "vue";
import Vuex from "vuex";
import applicationVuexModule from "./application";
import sessionVuexModule from "./session";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    application: applicationVuexModule,
    session: sessionVuexModule
  }
});
