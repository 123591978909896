import Vue from "vue";

export class Dictionary {
  /**
   * Class constructor.
   */
  constructor(options = {}) {
    const values = options?.entries ?? {};
    this._default = options?.default ?? "en";
    this._keys = Object.keys(values);

    // Normalize entries using key|language as key
    this._entries = this._keys.reduce((entries, key) => {
      Object.keys(values[key]).forEach(
        language => (entries[`${key}|${language}`] = values[key][language])
      );

      return entries;
    }, {});

    // Create an entry for each key
    this._key = Vue.observable(
      this._keys.reduce((translations, key) => {
        translations[key] = "???";
        return translations;
      }, {})
    );

    // Set current translations
    this.current = this._default;
  }

  /**
   * Set current language.
   * @param {string} language current language.
   */
  set current(language) {
    language = language ?? "";

    // Check if value changes
    if (this._current !== language) {
      this._current = language;

      // Update all keys translations
      this._keys.forEach(key => {
        this._key[key] = this.getForKey(key, language);
      });
    }
  }

  /**
   * Return current language.
   * @returns {string} current language.
   */
  get current() {
    return this._current;
  }

  /**
   * Return an object with all available keys.
   * @return {object} all available keys.
   */
  get key() {
    return this._key;
  }

  /**
   * Search a translations for the given key in the given or default language.
   * @param {string} key dictionary key.
   * @param {string} language requested language.
   * @returns {string} translation for the key.
   */
  getForKey(key, language) {
    return (
      this._entries[`${key}|${language ?? this._current}`] ??
      this._entries[`${key}|${this._default}`] ??
      key
    );
  }
}

export default {
  /**
   * Install dictionary instance.
   * @param {object} Vue vue instance
   * @param {object} options install options
   */
  install: function(Vue, options) {
    Vue.prototype.$dictionary = new Dictionary(options);
  }
};
