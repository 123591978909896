/**
 * Default exports
 */

/**
 * Return object with the given reference name or null.
 * @param {object} context reference context
 * @param {string} name reference name
 * @returns {object} object with the given reference name or null
 */
function getRefObject(context, name) {
  return name in context.$refs == false
    ? null
    : Array.isArray(context.$refs[name])
      ? context.$refs[name][0]
      : context.$refs[name];
}

export default {
  data: () => ({
    // Keep track of timeout
    delay_timeoutId: 0
  }),

  methods: {
    // Delay function call
    delay_execute: function(callback, timeout = 1000) {
      // Reset current timeout
      if (this.delay_timeoutId) {
        clearTimeout(this.delay_timeoutId);
        this.delay_timeoutId = 0;
      }

      // Create a delayed function call
      this.delay_timeoutId = setTimeout(() => {
        this.delay_timeoutId = 0;
        callback();
      }, timeout);
    },

    /**
     * Focus the object with the given reference name after the given timeout.
     * @param {String} refName reference name of the object to be focused
     * @param {Number} timeout milliseconds to wait before focusing (default 300)
     */
    delay_focus: function(refName, timeout = 300) {
      setTimeout(() => getRefObject(this, refName)?.focus(), timeout);
    },

    /**
     * Blur the object with the given reference name after the given timeout.
     * @param {String} refName reference name of the object to be blurred
     * @param {Number} timeout milliseconds to wait before blurring (default 300)
     */
    delay_blur: function(refName, timeout = 300) {
      setTimeout(() => getRefObject(this, refName)?.blur(), timeout);
    }
  }
};
