import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import server from "./plugins/server";
import alert from "./plugins/alert";
import dictionary from "./plugins/dictionary";
import vuetify from "./plugins/vuetify";

import Session from "./mixins/session";
import Rules from "./mixins/rules";
import Delay from "./mixins/delay";
import Dates from "./mixins/dates";
import Sizes from "./mixins/sizes";

Vue.use(require("vue-cookies"));

Vue.config.productionTip = false;
Vue.use(server, {
  baseUrl: process.env.VUE_APP_SERVER_BASE_URL || "",
  fullUrl: process.env.VUE_APP_SERVER_BASE_FULL_URL || ""
});
Vue.use(alert);

// global mixins
Vue.mixin(Session);
Vue.mixin(Rules);
Vue.mixin(Delay);
Vue.mixin(Sizes);
Vue.mixin(Dates);

// Create app
const vueApp = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
});

/**
 * Recursively apply all keys of source to destination.
 * @param {object} source source object
 * @param {object} destination destination object
 * @returns true if value has been applied
 */
function apply(source, destination) {
  if (source !== null && typeof source === "object") {
    Object.keys(source).forEach(key => {
      apply(source[key], destination[key]) || (destination[key] = source[key]);
    });
    return true;
  } else {
    return false;
  }
}

// Load dictionary
vueApp.$server
  .query({ url: "customization.json" })

  // Load customizations
  .then(customization => {
    // Set application properties
    vueApp.$store.commit("setAppTitle", customization.app.name);
    vueApp.$store.commit("setSenderEmail", customization.app.email);
    vueApp.$store.commit("setPrimaryColor", customization.app.primaryColor);
    vueApp.$store.commit("setSecondaryColor", customization.app.secondaryColor);
    vueApp.$store.commit("setDefaultLanguage", (customization.app.defaultLanguage || "").toLowerCase());
    vueApp.$store.commit("setAvailableLanguages", (customization.app.availableLanguages || []).map(locale => locale.toLowerCase()));
    vueApp.$store.commit("setEncryption", customization.app.encryption || false);
    vueApp.$store.commit("setEncryptionCommonKey", customization.app.encryptionCommonKey || false);
    vueApp.$store.commit("setEncryptionRsaBits", customization.app.encryptionRsaBits || 1024);

    // Change theme colors
    apply(
      {
        light: {
          navbar: customization.app.primaryColor,
          primary: customization.app.primaryColor,
          secondary: customization.app.secondaryColor
        },
        dark: {
          navbar: customization.app.primaryColor,
          primary: customization.app.primaryColor,
          secondary: customization.app.secondaryColor
        }
      },
      vueApp.$vuetify.theme.themes
    );

    // Load dictionary
    return vueApp.$server.query({ url: "dictionary.json" });
  })
  .then(entries => {
    // Create dictionary
    Vue.use(dictionary, {
      entries,
      default: vueApp.$store.getters.defaultLanguage
    });

    // Start the app
    vueApp.$mount("#app");
  });
