const applicationVuexModule = {
  state: {
    appTitle: "",
    senderEmail: "",
    primaryColor: "",
    secondaryColor: "",
    defaultLanguage: "en",
    availableLanguages: ["en"],
    encryption: false,
    encryptionCommonKey: false,
    encryptionRsaBits: 1024,
    requestedDocument: undefined
  },

  mutations: {
    setAppTitle: (state, value) => (state.appTitle = value),
    setSenderEmail: (state, value) => (state.senderEmail = value),
    setPrimaryColor: (state, value) => (state.primaryColor = value),
    setSecondaryColor: (state, value) => (state.secondaryColor = value),
    setDefaultLanguage: (state, value) => (state.defaultLanguage = value),
    setAvailableLanguages: (state, value) => (state.availableLanguages = value),
    setEncryption: (state, value) => (state.encryption = value),
    setEncryptionCommonKey: (state, value) => (state.encryptionCommonKey = value),
    setEncryptionRsaBits: (state, value) => (state.encryptionRsaBits = value),
    setRequestedDocument: (state, value) => (state.requestedDocument = value)
  },

  getters: {
    appTitle: state => state.appTitle,
    senderEmail: state => state.senderEmail,
    primaryColor: state => state.primaryColor,
    secondaryColor: state => state.secondaryColor,
    defaultLanguage: state => state.defaultLanguage,
    availableLanguages: state => state.availableLanguages,
    encryption: state => state.encryption,
    encryptionCommonKey: state => state.encryptionCommonKey,
    encryptionRsaBits: state => state.encryptionRsaBits,
    requestedDocument: state => state.requestedDocument
  }
};

export default applicationVuexModule;
