<template>
  <v-dialog v-model="visible" persistent overlay-opacity="0.7" width="400">
    <v-card>
      <v-card-title class="secondary">
        {{ $dictionary.key.APP_AUTH_CODE }}
      </v-card-title>

      <v-card-text class="mt-4">
        {{ $dictionary.key.APP_AUTH_CODE_DESCRIPTION }}

        <div class="d-flex justify-space-between">
          <v-text-field
            v-for="(_, index) in codeLength"
            v-bind:key="`digit-${index}`"
            v-bind:value="code[index]"
            v-bind:ref="`digit-${index}`"
            v-bind:autofocus="index == 0"
            v-on:keydown="keyPressed($event, index)"
            v-on:focus="fieldFocus(index)"
            class="centered-input text-h5 ma-1"
            background-color="green lighten-5"
            hide-details
            outlined
            readonly
          />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          v-on:click="codeConfirmed"
          v-bind:disabled="!codeFilled"
          text
        >
          {{ $dictionary.key.APP_CONFIRM }}
        </v-btn>
        <v-btn v-on:click="verified(false)" text>
          {{ $dictionary.key.APP_CANCEL }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AuthCode",

  data: () => ({
    visible: false,
    code: "",
    codeLength: 6,
    codeIsAlphanumeric: false,
    requestId: null,
    onSuccess: null,
    onFailure: null
  }),

  computed: {
    // Return true if code is filled
    codeFilled() {
      return this.code.length == this.codeLength;
    }
  },

  created() {
    // Register default two factor authentication handler
    this.$server &&
      (this.$server.default2FAHandler = requestId => {
        return new Promise((resolve, reject) => {
          const requestIdSplit = requestId.split("-");

          // Show auth code window and wait for the code
          this.code = "";
          this.requestId = requestId;
          this.codeLength =
            requestIdSplit.length > 2 ? parseInt(requestIdSplit[2]) || 6 : 6;
          this.codeIsAlphanumeric =
            requestIdSplit.length > 1 && requestIdSplit[1] == "a";
          this.onSuccess = resolve;
          this.onFailure = reject;
          this.visible = true;
        });
      });
  },

  methods: {
    // Text field gain focus
    fieldFocus(index) {
      // Reset from selected field on
      this.code = this.code.substr(0, index);
    },

    // Handle key press on text fields
    keyPressed(e, index) {
      // Always prevent default behavior
      e.preventDefault();

      const charValue = e.key.toUpperCase();
      const charCode = charValue.length == 1 ? charValue.charCodeAt(0) : 0;

      if (e.key == "Backspace" || e.key == "ArrowLeft") {
        // If the field is not empty just clear it else move to previous field
        index > 0 && this.delay_focus(`digit-${index - 1}`, 100);
      } else if (
        (charCode > 47 && charCode < 58) || // digit
        (this.codeIsAlphanumeric && charCode > 64 && charCode < 91) // upper case letter
      ) {
        this.code += charValue;

        // Focus next or blur last
        if (++index < this.codeLength) {
          this.delay_focus(`digit-${index}`, 100);
        } else {
          this.$refs[`digit-${this.codeLength - 1}`][0].blur();
        }
      }
    },

    // Code confirmed
    codeConfirmed() {
      this.$server
        .send({
          url: "/authentication",
          data: { id: this.requestId, code: this.code }
        })
        .then(() => this.verified(true));
    },

    // Handle authentication
    verified(response) {
      this.visible = false;

      if (response) {
        this.onSuccess();
      } else {
        // Simulate an html access forbidden response
        this.onFailure({ response: { status: 403 } });
      }
    }
  }
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
