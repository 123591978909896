/**
 * Default exports
 */
export default {
  computed: {
    rules_required() {
      return value => !!value || this.$dictionary.key.APP_RULE_MANDATORY;
    },
    rules_integer() {
      return value =>
        !value ||
        /^[0-9]*$/.test(value) ||
        this.$dictionary.key.APP_RULE_INVALID_VALUE;
    },
    rules_email() {
      return value =>
        /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(
          value
        ) || this.$dictionary.key.APP_RULE_INVALID_EMAIL;
    },
    rules_password() {
      return value =>
        /^((?!.*[\s])(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&_\-*]).{8,16})$/.test(
          value
        ) || this.$dictionary.key.APP_RULE_INVALID_PASSWORD;
    },
    rules_hex() {
      return value =>
        /^#[0-9A-Fa-f]{6}$/.test(value) ||
        this.$dictionary.key.APP_RULE_INVALID_VALUE;
    }
  }
};
