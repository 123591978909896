import Vue from "vue";
import axios from "axios";

const header2FARequestId = "app-2fa-id";
const header2FACodeLength = "app-2fa-length";

export class AJAXServer {
  /**
   * Class constructor
   * @param {string} baseUrl relative url
   * @param {string} fullUrl complete url
   */
  constructor(baseUrl = "", fullUrl = "") {
    // Default error handler
    this.defaultErrorHandler = (error, errorFilter) => {
      errorFilter === null ||
        typeof errorFilter !== "function" ||
        errorFilter(error);
    };

    // Default two factor authentication handler
    this.default2FAHandler = (requestId, codeLength) => {
      console.error("2FA request received and not handled", {
        requestId,
        codeLength
      });

      // Simulate an html access forbidden response
      return Promise.reject({ response: { status: 403 } });
    };

    this._requests = Vue.observable({ count: 0 });
    this._baseUrl = baseUrl;
    this._fullUrl = fullUrl;
  }

  /**
   * Return true if there are pending queries.
   * @returns {boolean} true if there are pending queries
   */
  get pendingQueries() {
    return this._requests.count > 0;
  }

  /**
   * Return server url for given values.
   * @param {string} base server base url
   * @param {string} url relative url
   * @param {object} parameters parameters
   * @returns {string} server url
   */
  url(base, url, parameters) {
    const uri = base ? [base, url].join("/") : url;

    return parameters
      ? uri +
          "?" +
          Object.keys(parameters)
            .reduce((result, key) => {
              result.push(key + "=" + encodeURIComponent(parameters[key]));
              return result;
            }, [])
            .join("&")
      : uri;
  }

  /**
   * Return server local url.
   * @param {string} url url
   * @param {object} parameters parameters
   * @returns {string} server local url
   */
  localUrl(url, parameters) {
    return this.url(this._baseUrl, url, parameters);
  }

  /**
   * Return full server url.
   * @param {string} url url
   * @param {object} parameters parameters
   * @returns {string} server url
   */
  fullUrl(url, parameters) {
    return this.url(this._fullUrl, url, parameters);
  }

  /**
   * Retrieve data from server.
   * @param {object} queryParams query parameters
   * @param {err => boolean} errorFilter filter error for default handler
   * @returns {promise} query promise
   */
  async query(queryParams, errorFilter = null) {
    return this.doQuery("get", queryParams, errorFilter);
  }

  /**
   * Call server command.
   * @param {object} queryParams query parameters
   * @param {err => boolean} errorFilter filter error for default handler
   * @returns {promise} query promise
   */
  async execute(queryParams, errorFilter = null) {
    return this.doQuery("get", queryParams, errorFilter);
  }

  /**
   * Delete data on the server.
   * @param {object} queryParams query parameters
   * @param {err => boolean} errorFilter filter error for default handler
   * @returns {promise} query promise
   */
  async delete(queryParams, errorFilter = null) {
    return this.doQuery("delete", queryParams, errorFilter);
  }

  /**
   * Save data to the server.
   * @param {object} queryParams query parameters
   * @param {err => boolean} errorFilter filter error for default handler
   * @returns {promise} query promise
   */
  async save(queryParams, errorFilter = null) {
    return this.doQuery("post", queryParams, errorFilter);
  }

  /**
   * Send data to the server.
   * @param {object} queryParams query parameters
   * @param {err => boolean} errorFilter filter error for default handler
   * @returns {promise} query promise
   */
  async send(queryParams, errorFilter = null) {
    return this.doQuery("post", queryParams, errorFilter);
  }

  /**
   * Update data on the server.
   * @param {object} queryParams query parameters
   * @param {err => boolean} errorFilter filter error for default handler
   * @returns {promise} query promise
   */
  async update(queryParams, errorFilter = null) {
    return this.doQuery("patch", queryParams, errorFilter);
  }

  /**
   * Upload file on the server.
   * @param {object} queryParams query parameters
   * @param {err => boolean} errorFilter filter error for default handler
   * @returns {promise} query promise
   */
  async upload(queryParams, errorFilter = null) {
    if (queryParams && "data" in queryParams && queryParams.data) {
      const data = queryParams.data;

      queryParams.headers = { "Content-Type": "multipart/form-data" };
      queryParams.data = Object.keys(data).reduce((formData, key) => {
        const value = data[key];

        if (Array.isArray(value)) {
          value.forEach(value => formData.append(key, value));
        } else {
          formData.append(key, value);
        }

        return formData;
      }, new FormData());
    }

    return this.doQuery("post", queryParams, errorFilter);
  }

  /**
   * Query the server.
   * @param {string} method query method
   * @param {object} queryParams axios query parameters
   * @param {err => boolean} errorFilter filter error for default handler
   * @returns {promise} query promise
   */
  async doQuery(method, queryParams, errorFilter = null) {
    // Check parameters
    if (typeof method !== "string" || !method)
      throw "ERROR: doQuery - missing method";
    else if (typeof queryParams !== "object" || !queryParams)
      throw "ERROR: doQuery - missing query parameters";
    else if ("url" in queryParams === false || !queryParams.url)
      throw "ERROR: doQuery - missing query url";

    const fullResponse = queryParams.fullResponse;

    const config = Object.assign({}, queryParams);
    config.method = method;
    config.url = this.url(this._baseUrl, queryParams.url);

    this._requests.count++;
    return axios(config)
      .then(response => {
        this._requests.count--;

        // Check if a two factor authentication is requested
        return response.status == 202 && response.headers[header2FARequestId]
          ? this.default2FAHandler(
            response.headers[header2FARequestId],
            parseInt(response.headers[header2FACodeLength]) || 6
          )
          : Promise.resolve(fullResponse ? response : response.data);
      })
      .catch(error => {
        this._requests.count--;
        this.defaultErrorHandler(error.response, errorFilter);
        return Promise.reject(error.response);
      });
  }
}

export default {
  /**
   * Install default server instance.
   * @param {object} Vue vue instance
   * @param {object} options install options
   */
  install: function(Vue, options) {
    Vue.prototype.$server = new AJAXServer(options.baseUrl, options.fullUrl);
  }
};
